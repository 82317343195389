@font-face {
  font-family: "poppins-light";
  src: local("Poppins-Light"),
    url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "poppins-medium";
  src: local("Poppins-Medium"),
    url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "poppins-sbold";
  src: local("Poppins-SemiBold"),
    url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

html *,
body * {
  font-family: "poppins-light" !important;
}

b {
  font-family: "poppins-medium" !important;
}

strong {
  font-family: "poppins-sbold" !important;
}

.main-container {
  min-height: calc(100vh - 400px);
}

.btn-primary {
  background-color: #003da7 !important;
  border-color: #003da7 !important;
}

.btn:hover {
  background-color: #01348d !important;
  border-color: #01348d !important;
}

.heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3.5rem;
  color: #003da7;
  margin-bottom: 28px;
}

.welcome-text {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 50px;
  color: #003da7;
  margin-bottom: 28px;
  font-family: 'poppins-sbold' !important;
}

.mainColor {
  color: #003da7 !important;
}

.main-color {
  background-color: #003da7 !important;
  color: #fff;
}

.stepHeader {
  color: rgb(206, 186, 151);
  font-weight: bolder;
}

.infoText {
  color: grey;
  font-size: medium;
}

input[type="text"],
input[type="email"],
select,
.dropdown-toggle {
  background-color: #e0e0e0;
  height: 45px;
  border-radius: 12px;
  outline-width: 0;
  border: none;
}

.w-auto,
.w-25,
.form-select,
textarea,
.dropdown-toggle {
  background-color: #e0e0e0 !important;
  border-radius: 12px !important;
}

.contactSubmit {
  background-color: #ceba97;
  border: none;
}

.btnOTP {
  color: #fff;
  background-color: #a7a7a7;
  border: none;
}

.dropdown-toggle:focus {
  outline: none !important;
  border: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-color: #86b7fe;
  outline: 0;
}

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 13rem !important;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .text-secondary {
    font-size: 1rem;
  }

  .loadingBars {
    height: 100% !important;
    width: 100% !important;
    transform: translate(0, 0) !important;
  }
}

.footer-border {
  border-top: 1px solid #003da7 !important;
}

.footer-home {
  border-top: 1px solid #fff !important;
  background-color: #003da7;
  color: #fff;
}

.footer-home .f-line {
  background-color: #FFF;
}

button.btn-outline {
  border-radius: 12px;
  padding: 3px 10px;
  font-weight: 700;
  background-color: transparent;
  color: #003da7;
  border: 3px solid #003da7;
  margin-bottom: 3rem;
  cursor: pointer;
}

label.btn-outline {
  border-radius: 12px;
  padding: 3px 10px;
  font-weight: 700;
  background-color: transparent;
  color: #003da7;
  border: 3px solid #003da7;
  margin-bottom: 3rem;
}

.f-line {
  width: 1px;
  height: 50px;
  background-color: #003da7;
}

/* OTP Confirm */
.err-msg {
  background-color: #e5c5ca;
  color: #a7011d;
  border: #a7011d solid 1px;
  border-radius: 5px;
}

.colorGold {
  color: #a28a5c;
}

.loadingBars {
  display: flex;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  height: calc(100% - 3rem);
  width: calc(100% - 3rem);
  justify-content: center;
  align-items: center;
  transform: translate(-1.5rem, -1.5rem);
  z-index: 1;
}

.loadingBarsContact {
  display: flex;
  position: absolute;
  background: rgba(0, 61, 167, 0.9);
  height: calc(100% + 1.5rem);
  width: calc(100% + 1.5rem);
  justify-content: center;
  align-items: center;
  transform: translate(-1.5rem, -1.5rem);
  z-index: 1;
}

.multiSelectContainer input {
  margin-top: 0 !important;
}

.searchWrapper,
.searchWrapper.singleSelect {
  background-color: #e0e0e0 !important;
  min-height: 45px !important;
  border-radius: 12px !important;
  outline-width: 0 !important;
  border: none !important;
  padding-left: 10px !important;
  color: #FFF;
  ;
}

.chip,
.chip.singleChip,
.highlightOption {
  background-color: #003da7 !important
}

.multiSelectContainer li:hover {
  background-color: #0249c5 !important
}

.addBtn {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.btnSkip {
  border-color: #01348d !important;
  color: #01348d !important;
}

.btnSkip:hover {
  border-color: #01348d !important;
  color: #FFF !important;
}

.mh-fh {
  min-height: calc(100vh - (300px + 3rem));
}