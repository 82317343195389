.heading {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 30px;
  color: #003da7;
  margin-bottom: 28px;
}
@media (min-width: 768px) {
  .heading {
    font-size: 58px;
  }
}
.line {
  width: 4rem;
  height: 1px;
  margin-bottom: 1rem;
  background-color: #7c9bd2;
}
.v-line {
  content: "";
  width: 2px;
  position: absolute;
  height: 400px;
  background-color: white;
  top: 30px;
  right: 0;
  background-color: #7c9bd2;
}
